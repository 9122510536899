<template>
  <div>
    <!-- <h3 class="mb-2">COA 관리</h3> -->
    <SetPopup ref="confirm" />
    <v-form lazy-validation ref="form">
      <div class="filter-box filter-box--row">
        <ul class="filter-list_free">
          <li style="width: 120px">
            <h4>Lot No</h4>
            <v-text-field outlined dense placeholder="" v-model="input.lotNo" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
          </li>
          <li style="width: 120px">
            <h4>Code Grade</h4>
            <v-text-field outlined dense placeholder="" v-model="input.code" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
          </li>
          <li style="width: 120px">
            <h4>수령업체명</h4>
            <v-text-field outlined dense placeholder="" v-model="input.pickpart" :rules="[this.validSet.company]" @keyup.enter="search"></v-text-field>
          </li>
          <li style="width: 120px">
            <h4>수령자</h4>
            <v-text-field outlined dense placeholder="" v-model="input.request_name" :rules="[this.validSet.name]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>요청일</h4>
            <div class="dp--row">
              <v-menu v-model="picker1" :close-on-content-click="false" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="input.from_request_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="picker1 = false" v-model="input.from_request_date"></v-date-picker>
              </v-menu>
              <i class="ml-1 mr-1">~</i>
              <v-menu v-model="picker2" :close-on-content-click="false" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="input.to_request_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="picker2 = false" v-model="input.to_request_date"></v-date-picker>
              </v-menu>
            </div>
          </li>
          <li>
            <h4>제조일</h4>
            <div class="dp--row">
              <v-menu v-model="picker3" :close-on-content-click="false" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="input.from_produce_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="picker3 = false" v-model="input.from_produce_date"></v-date-picker>
              </v-menu>
              <i class="ml-1 mr-1">~</i>
              <v-menu v-model="picker4" :close-on-content-click="false" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="input.to_produce_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="picker4 = false" v-model="input.to_produce_date"></v-date-picker>
              </v-menu>
            </div>
          </li>
          <li style="width: 120px">
            <h4>상태</h4>
            <div class="wrapperTop">
              <v-select :items="code.Q" v-model="input.stat_str" outlined id="work" />
            </div>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed outlined class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- <div style="width: 100%" class="mb-4">
        <div class="wrapperSpace pa-4 pt-6 sch">
          <v-row class="pl-2">
            <v-col cols="12" sm="4">
              <h4>Lot No</h4>
              <v-text-field
                outlined
                dense
                placeholder="Lot No를 입력해 주세요."
                v-model="input.lotNo"
                :rules="[this.validSet.commonCodeHipen]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <h4>샘플코드</h4>
              <v-text-field
                outlined
                dense
                placeholder="샘플코드를 입력해 주세요."
                v-model="input.code"
                :rules="[this.validSet.commonCodeHipen]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <h4>요청자</h4>
              <div class="wrapperTop">
                <v-text-field
                  outlined
                  dense
                  placeholder="요청자를 입력해 주세요."
                  v-model="input.request_name"
                  :rules="[this.validSet.name]"
                ></v-text-field>
                <v-card-actions>
                  <v-btn depressed outlined @click="reset">초기화</v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn depressed color="primary" @click="search">검색</v-btn>
                </v-card-actions>
              </div>
            </v-col>
          </v-row>
        </div>
      </div> -->
    </v-form>
    <h3 class="mt-2 mb-2 dp--row">
      <!-- 목록 -->
      <v-btn depressed class="btn-download" @click="exelDownload" style="width: 143px; margin-left: auto">엑셀 다운로드</v-btn>
    </h3>
    <!-- <div class="wrapperSpace pr-2">
      <div>
        <h4 class="mt-4 mb-2">목록</h4>
      </div>
      <v-btn
        depressed
        color="primary"
        @click="exelDownload"
        style="width: 143px"
        >엑셀 다운로드</v-btn
      >
    </div>
    <hr /> -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" @click="click" />
  </div>
</template>
<script>
import validSet from '@/assets/valid';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
// eslint-disable-next-line no-unused-vars
import { columns, fields, rows, height } from '@/assets/grid/coa';
import { coalist, coadetail, resultsList, coaUpdate } from 'api/sample/sample';
import { getCoapdfFile, getMsdspdfFile, getReportFile2 } from 'api/file';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      id: '',
      picker1: false,
      picker2: false,
      picker3: false,
      picker4: false,
      validSet,
      grid: 'cda',
      input: {
        lotNo: '',
        code: '',
        request_name: '',
        pickpart: '',
        pageSize: 100000,
        from_request_date: '',
        to_request_date: '',
        from_produce_date: '',
        to_produce_date: '',
        stat_str: '전체',
      },
      origin: [],
      items: [],
      settings: {
        columns,
        fields,
        rows: 8,
        height: 650,
        errorMessage: '내역이 존재하지 않습니다',
        radio: true,
      },
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('member', ['SET_USER_INFO']),
    valid() {
      return this.$refs.form.validate();
    },
    async exelDownload() {
      const idx = this.$refs.grid.getCheckedRowIdxRadio();
      if (idx !== undefined && !isNaN(idx)) {
        let row = this.origin[idx];
        // 안되면 row에 lotNo 맞는지 확인해보기
        // eslint-disable-next-line no-self-assign
        row.lotNo = row.lotNo;
        await resultsList(row).then(async res => {
          const dt = res.data.data;
          // const sampleDetail = makeCOASpec(dt, row.lotNo, row.code)
          await coaUpdate({
            memberId: this.id,
            lotNo: row.lotNo,
            results: dt.items,
          });
          await coadetail(row).then(res => {
            row = res.data.data;
            if (row.coaexcel_path) {
              getReportFile2(row.lotNo, row.coaexcel_path);
            } else if (!row.coaexcel_path) {
              this.openPopup('COA 엑셀파일이 존재하지 않습니다');
            }
          });
        });
      } else {
        this.openPopup('다운로드할 행 선택해주세요');
      }
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    async search(v) {
      console.log("1111111111111");
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await coalist({
          ...this.input,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = false;

        const response = res.data;
        const items = response.data.items;
        this.items = response.data.items;
        const page = response.data.params;
        let filteredItems = items;

        if (this.input.from_request_date) {
          const fromDate = new Date(this.input.from_request_date);
          filteredItems = filteredItems.filter(item => new Date(item.request_date) >= fromDate);
        }
        if (this.input.to_request_date) {
          const toDate = new Date(this.input.to_request_date);
          filteredItems = filteredItems.filter(item => new Date(item.request_date) <= toDate);
        }
        if (this.input.from_produce_date) {
          const fromDate = new Date(this.input.from_produce_date);
          filteredItems = filteredItems.filter(item => new Date(item.produce_date) >= fromDate);
        }
        if (this.input.to_produce_date) {
          const toDate = new Date(this.input.to_produce_date);
          filteredItems = filteredItems.filter(item => new Date(item.produce_date) <= toDate);
        }
        if (this.input.stat_str == '완료') {
          const stat = this.input.stat_str;
          filteredItems = filteredItems.filter(item => item.stat_str == stat);
        }
        if (this.input.stat_str == '대기') {
          const stat = '대기';
          filteredItems = filteredItems.filter(item => item.stat_str == stat);
        }
        if (this.input.pickpart) {
          const pickpart = this.input.pickpart;
          filteredItems = filteredItems.filter(item => {
            if (item.pickpart === undefined) {
              return false;
            }
            return item.pickpart.includes(pickpart);
          });
        }

        this.$refs.grid.loadData(filteredItems, ['reg_dt', 'delivery_date']);
        this.$refs.grid.setPage(page);
        this.origin = filteredItems;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    reset() {
      this.input = {
        lotNo: '',
        code: '',
        request_name: '',
        pickpart: '',
        pageSize: 100000,
        from_request_date: '',
        to_request_date: '',
        from_produce_date: '',
        to_produce_date: '',
      };
    },
    loadData(v) {
      this.search(v);
    },
    dbClick(data) {
      this.$emit(
        'dbClick',
        _.filter(this.origin, v => {
          return v.lotNo === data.lotNo;          
        })[0],
      );
    },
    async click(data) {
      console.log(data,"data");
      if (data.cellType !== "header") {
        try {
          const row = this.$refs.grid.getJsonRows();
          const coaPath = row[data.itemIndex].coapdf_path;
          const msdsPath = row[data.itemIndex].msdspdf_path;
          
          if (data.fieldName == 'coapdf_path' && coaPath) {
            console.log("coa");
            let paths = coaPath.split(',');
            for (let path of paths) {
              path = path.trim();
              await getCoapdfFile(path);
            }
          } else if (data.fieldName == 'msdspdf_path' && msdsPath) {
            console.log("msds");
            let paths = msdsPath.split(',');
            for (let path of paths) {
              path = path.trim();
              await getMsdspdfFile(path);
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      }
      
    },
    resizeGrid() {
      const totalHeight = window.innerHeight; // 현재 윈도우의 내부 높이
      const targetGrid = document.querySelector(`div#${this.grid}`);
      let dynamicHeight = 360;
      if (this.showSearchOptions) {
        dynamicHeight += 70;
      }
      targetGrid.style.height = `${totalHeight - dynamicHeight}px`; // 그리드 컨테이너의 높이 조정
    },
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.loadData();
    this.resizeGrid(); // 초기 로드 시 그리드 크기 조절
    window.addEventListener('resize', this.resizeGrid); // 윈도우 크기 변경 시 그리드 크기 조절
  },
  computed: {
    ...mapState('common', ['code']),
  },
  components: {
    RealGrid,
    SetPopup,
  },
};
</script>
<style lang="scss"></style>
