<template>
  <div :class="realClass">
    <div :id="gridName" ref="realgrid" :style="'width: 100%; height: ' + calHeight() + 'px'" v-show="existRow"></div>
    <div v-show="!existRow">
      <div class="wrapper ma-16">
        <h4>{{ settings.errorMessage }}</h4>
      </div>
    </div>
    <!-- <div v-show="gridName == 'partnerAddressList' && selectedRow != null" :style="buttonContainerStyle" class="button-container">
      <button type="button" class="btn-row-address btn-address-edit" @click="onAddressEditClick">수정</button>
      <button type="button" class="btn-row-address btn-address-copy" @click="onAddressCopyClick">복사</button>
    </div> -->
    <!-- <div class="text-center mt-2 wrapper" v-if="!nonePage && existRow">
      <v-pagination
        depressed
        v-model="page.currentPage"
        :length="Math.ceil(page.totalRows / page.pageSize)"
        :total-visible="10"
      >
      </v-pagination>
    </div> -->
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
// import { EventBus } from '../plugins/eventBus';
import { GridView, LocalDataProvider } from 'realgrid';
import validSet from '@/assets/valid';
import { isEmptyOrWhitespace } from '@/assets/grid/gridUtill';

export default {
  name: 'RealGrid',
  props: ['domName', 'settings', 'nonePage', 'oneLineGrid'],
  computed: {
    existRow() {
      if (this.settings.blankGrid) {
        return true;
      }
      return this.dp && this.dp.getRowCount() > 0;
    },
    realClass() {
      return {
        real: true, // 항상 적용되는 클래스
        specialGrid: this.isSpecialGrid, // 조건부 클래스
      };
    },
  },
  data: function () {
    return {
      selectedRow: null,
      postEditType: '',
      buttonContainerStyle: {
        position: 'absolute',
        top: '0px',
        left: '610px', // 그리드의 오른쪽에 위치
        display: 'flex',
        zIndex: 1000, // z-index를 높여서 버튼이 앞쪽에 오도록 설정
      },
      isSpecialGrid: false,
      gridName: this.domName,
      gv: null,
      dp: null,
      page: {
        currentPage: 1,
        totalRows: 10,
        pageSize: 10,
      },
      missingKeyColumns: {}, // 조성이름이 없는 경우.
      missingValueColumns: {}, // 조성볼륨이 없는 경우.
      firstClick: this.settings.firstClick,
      fitStyle: null,
    };
  },
  watch: {
    'page.currentPage': function (v) {
      this.$emit('changePage', v);
    },
  },
  methods: {
    // 컬럼 width, data 길이에 맞게 자동 조절, data의 편집과(추가, 삭제, 조회) 레이아웃 저장/초기화 시 호출.
    commonSetFitLayout() {
      // realGrid가 화면에 다 그려진 후 함수 실행
      this.$nextTick(() => {
        // 에러 발생에 대한 try 문을 밖에서 안으로 옮김.
        try {
          // visible true인 컬럼명이 담긴 배열

          console.log("methods");
          let colName = this.gv.getColumnNames(true, false);
          colName.forEach(col => {
            if (this.gridName == 'partnerMaster') {
              // if (col === 'address' || col === 'address1' || col === 'address2') {
              //   return;
              // }
              this.gv.fitLayoutWidth(col, true);
              this.gv.layoutByColumn(col).cellWidth += 30;
            } else {
              if (col === 'msdspdf_path1' || col === 'coapdf_path1' || col === 'stat_standard1' || col === 'salestype1' || col == 'address1' || col == 'address2' || col == 'code_title1') {
                return;
              }
              // 컬럼명, 최대, 최소 width, 컬럼 헤더 텍스트의 길이도 기준으로 포함
              this.gv.fitLayoutWidth(col, true);
              // 변경된 width에서도 간소한 차이로 컬럼의 width가 부족한 상황이 존재하므로,30 추가
              this.gv.layoutByColumn(col).cellWidth += 30;
            }
          });
        } catch {
          //
        }
      });
    },
    // 선택한 row 조성항목을 문제 없이 저장한 리스트를 grid에서 삭제한다.
    removeItemsById(removeIdxs) {
      this.dp.removeRows(removeIdxs);
    },
    unsetGrid() {
      if (this.gv.getJsonRows().length === 0) {
        // 모든 행 제거
        this.dp.clearRows();
        this.gv.destroy();
        this.initializedGrid();
        this.firstClick = true;
      }
      // if (!initialized) this.gv.checkAll(false);
    },
    // addRow(newRow) {
    //   // add row.
    //   this.dp.addRow(newRow);
    //   this.$nextTick(() => {
    //     if (this.firstClick) {
    //       this.triggerFirstRowClick();
    //     }
    //   });
    // },
    addRow(newRow) {
      if (this.dp && newRow) {
        if (newRow.editabled) {
          newRow.fullAddress = `${newRow.address1 || ''} ${newRow.address2 || ''}`.trim();

          const itemIndex = newRow.rowIndex;
          delete newRow.remove;
          // delete newRow.copy;
          // delete newRow.edit;
          delete newRow.rowIndex;
          // this.gv.setValue(itemIndex, newRow);
          this.dp.updateRow(itemIndex, newRow);
          this.gv.refresh();
        } else {
          if (this.gridName == 'partnerAddressList') {
            newRow.fullAddress = `${newRow.address1 || ''} ${newRow.address2 || ''}`.trim();
          }
          this.dp.addRow(newRow);
          this.$nextTick(() => {
            if (this.firstClick) {
              this.triggerFirstRowClick();
            }
          });
          this.gv.refresh();
          this.postEditType = '';
        }
      } else {
        console.error('DataProvider is null');
      }
    },
    // 클릭 트리거 발생 시 실제 onCellClicked를 실행한다.
    triggerFirstRowClick() {
      const rowIndex = 0;
      const columnIndex = 0;
      const column = this.gv.getColumn(columnIndex); // getColumn 메서드 사용
      if (column) {
        const clickData = {
          itemIndex: rowIndex,
          column: column,
          fieldIndex: columnIndex,
          dataRow: this.dp.getJsonRow(rowIndex),
        };
        // 이벤트 핸들러 직접 호출
        if (typeof this.gv.onCellClicked === 'function') {
          this.gv.onCellClicked(this.gv, clickData);
          this.$emit('click', { grid: this.gv, clickData });
        }
        this.firstClick = false;
      } else {
        console.error('지정된 인덱스에 해당하는 컬럼이 없습니다.');
      }
    },
    onRowClicked(rowIndex) {
      const item = this.dp.getJsonRow(rowIndex);
      const grid = this.gv;
      this.$emit('click', { grid, item });
    },
    loadData: function (row, dateCol) {
      if (this.settings.blankGrid) {
        this.dp.setRows(row);
        return false;
      }
      this.firstClick = this.settings.firstClick;
      if (dateCol && dateCol.length > 0) {
        _.each(row, v => {
          _.each(dateCol, o => {
            if (v[o]) {
              v[o] = moment(v[o]).format('YYYY-MM-DD');
            }
          });
        });
      }

      this.dp.setRows(row);

      // 부모에서 settings.firstClick -> true이면 첫번째 row 데이터 클릭 트리거 발생
      if (this.firstClick) {
        this.triggerFirstRowClick();
      }
    },
    getJsonRows: function () {
      // 그리드의 데이터로 연동 변경
      return this.gv.getJsonRows();
      // return this.dp.getJsonRows(0, -1);
    },
    getRow: function () {
      return this.dp.getRows(0, -1);
    },
    getJsonRow: function () {
      return this.dp.getJsonRow(0);
    },
    getJsonRowByIdx: function (idx) {
      return this.gv.getValues(idx);
    },
    getJsonAllRow: function () {
      return this.dp.getRows(0, -1);
    },
    getCol: function () {
      return this.gv.getColumns();
    },
    setGroup(groupingInfo) {
      this.gv.setColumnLayout(groupingInfo);
    },
    setColumnsEditable() {
      this.gv.columnByField('ww').editable = false;
      for (let i = 1; i <= 10; i++) {
        if (i < 9) {
          this.gv.columnByField(`solvent${i}`).editable = false;
          this.gv.columnByField(`solventVol${i}`).editable = false;
        }
        if (i < 4) {
          this.gv.columnByField(`salt${i}`).editable = false;
          this.gv.columnByField(`saltVol${i}`).editable = false;
        }
        this.gv.columnByField(`add${i}`).editable = false;
        this.gv.columnByField(`addVol${i}`).editable = false;
      }
    },
    setColumnsEditable2() {
      this.gv.columnByField('ww').editable = true;
      for (let i = 1; i <= 10; i++) {
        if (i < 9) {
          this.gv.columnByField(`solvent${i}`).editable = true;
          this.gv.columnByField(`solventVol${i}`).editable = true;
        }
        if (i < 4) {
          this.gv.columnByField(`salt${i}`).editable = true;
          this.gv.columnByField(`saltVol${i}`).editable = true;
        }
        this.gv.columnByField(`add${i}`).editable = true;
        this.gv.columnByField(`addVol${i}`).editable = true;
      }
    },
    resultSetColumnsEditable() {
      this.gv.columnByField('spec').editable = false;
      this.gv.columnByField('unit').editable = false;
      this.gv.columnByField('vol').editable = false;
      this.gv.columnByField('low').editable = false;
      this.gv.columnByField('mid').editable = false;
      this.gv.columnByField('hig').editable = false;
      this.gv.columnByField('sub').editable = false;
      this.gv.columnByField('rv').editable = false;
    },
    setRowStyleCallback() {
      // eslint-disable-next-line no-unused-vars
      this.gv.setRowStyleCallback(function (_grid, _item, _fixed) {
        // eslint-disable-next-line no-constant-condition
        if (true) {
          return 'disabled-color'; // 'disabled-color' 스타일 적용
        } else {
          //
        }
      });
    },
    setRowStyleCallback2() {
      // eslint-disable-next-line no-unused-vars
      this.gv.setRowStyleCallback(function (_grid, _item, _fixed) {
        return 'normal-color'; // 'disabled-color' 스타일 적용
      });
    },
    // tr class  초기화.
    setRowStyleCallbackDefault() {
      // eslint-disable-next-line no-unused-vars
      this.gv.setRowStyleCallback(function (_grid, _item, _fixed) {
        return 'rg-data-row';
      });
    },
    // 라인 색상 클래스 초기화.
    allRemoveUserCellStyle() {
      const columns = this.$el.querySelectorAll(`div#${this.gridName} div.rg-body table tbody tr td.rg-data-cell`);
      let count = columns.length;
      for (let i = 0; i < count; i++) {
        columns[i].classList.remove('rg-validation-focus');
      }
    },
    findColumnIndex(name, columns) {
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].name == name) {
          return i;
        }
      }
      return -1;
    },
    getCheckedRowExecl: function (columns) {
      const idx = this.gv.getCheckedRows(true, false, false);
      const allRow = this.dp.getRows(0, -1);
      const rows = [];
      let columnIndex = '';

      // 특수한 값을 보이기 위한 코드
      if (this.gridName == 'partnerMaster') {
        columnIndex = this.findColumnIndex('open_yn', columns);
      }
      _.each(idx, i => {
        rows.push(allRow[i]);
      });

      const excelData = [];
      _.each(rows, row => {
        const obj = {};
        _.each(row, (value, idx) => {
          let headerText = columns[idx].header.text;
          if (this.gridName == 'partnerMaster' && idx === 0) {
            headerText = '고객사코드'; // undefined 라고 표시되는 컬럼명.
          }
          if (columnIndex === idx) {
            obj[headerText] = value == 'Y' ? '운영' : '미운영';
          } else {
            obj[headerText] = value;
          }
        });
        excelData.push(obj);
      });
      return excelData;
    },
    getCheckedRow: function () {
      const idx = this.gv.getCheckedRows(true, false, false);
      const rows = [];
      _.each(idx, v => {
        // 멀티 선택을 위한 새로운 코드.
        rows.push({ idx: v, data: this.dp.getJsonRow(v) });
      });
      return rows;
    },
    getCheckedRowIdx: function () {
      return this.gv.getCheckedRows(true, false, false);
    },
    getCheckedRowIdxRadio: function () {
      return parseInt(this.getCheckedRowIdx().join(''));
    },
    setColor() {},
    setPage: function (v) {
      this.page = {
        currentPage: v.currentPage ? Number(v.currentPage) : 1,
        totalRows: v.totalRows ? v.totalRows : 10,
        pageSize: v.pageSize ? Number(v.pageSize) : 10,
      };
    },
    getPage: function () {
      return this.page;
    },
    calHeight() {
      if (this.gridName == 'newSample' || this.gridName == 'adminSample') {
        return false;
      }
      const ht = this.settings.height ? this.settings.height : 540;
      return ht;
    },
    // 조성 데이터 관련 이름과 볼륨을 쌍으로 묶어 비어 있는 경우를 체크한다.
    // 어느 하나라도 비어 있다면 Object로 저장한다.
    // onEditRowChanged에서 값이 비어 있지 않다면 class를 remove한다.
    checkKeyValuePairs(rowIndex, colIndex) {
      const columns = this.gv.getColumns();
      const colCount = columns.length - colIndex;
      const blankKeys = {};
      const blankValues = {};
      this.missingKeyColumns = {};
      this.missingValueColumns = {};
      const wwUsed = columns.some(column => column.fieldName === 'ww');

      // 컬럼을 순회하면서 키-값 쌍 확인, i += 2로 컬럼을 쌍으로 처리
      for (let i = colIndex; i < columns.length - 1; i += 2) {
        const [keyColumn, valueColumn] = [columns[i], columns[i + 1]];

        if (['ww', 'sum'].includes(keyColumn.fieldName) || ['ww', 'sum'].includes(valueColumn.fieldName)) {
          continue;
        }

        const [keyValue, valueValue] = [this.gv.getValue(rowIndex, keyColumn.fieldName), this.gv.getValue(rowIndex, valueColumn.fieldName)];
        const [isKeyEmpty, isValueEmpty] = [isEmptyOrWhitespace(keyValue), isEmptyOrWhitespace(valueValue)];

        if (isKeyEmpty === isValueEmpty) {
          continue;
        }

        const targetPos = this.calculateTargetPosition(isKeyEmpty, wwUsed);
        const targetTd = this.$el.querySelector(`div#${this.gridName} div.rg-body table tr:first-child td:nth-child(${i + targetPos})`);
        targetTd.classList.add('rg-validation-focus');

        if (isKeyEmpty) {
          blankKeys[keyColumn.fieldName] = { rowIndex: rowIndex, pariName: valueColumn.fieldName, target: targetTd };
          this.setBlankColumnFocused(rowIndex, columns[colCount > i + 1 ? i + 1 : i]);
        } else {
          blankValues[valueColumn.fieldName] = { rowIndex: rowIndex, pariName: keyColumn.fieldName, target: targetTd };
          this.setBlankColumnFocused(rowIndex, columns[colCount > i + 1 ? i + 2 : i]);
        }
      }
      //
      this.updateMissingColumnsResults(blankKeys, blankValues);
      return { key: this.missingKeyColumns, value: this.missingValueColumns };
    },
    // 빈 셀의 라인 색상을 변경하려는 child 값.
    calculateTargetPosition(isKeyEmpty, wwUsed) {
      if (this.gridName == 'delivaryReportDetail') {
        return isKeyEmpty ? 1 : 2;
      }
      return isKeyEmpty ? (wwUsed ? 0 : 1) : wwUsed ? 1 : 2;
    },
    // 조성 항목 쌍 중 빈 셀 대신 옆쪽 셀 포커스.
    setBlankColumnFocused(rowIndex, foucsColumn) {
      this.gv.setCurrent({
        itemIndex: rowIndex,
        column: foucsColumn.fieldName,
      });
    },
    // 조성 항목의 쌍 중 빈 셀 저장.
    updateMissingColumnsResults(blankKeys, blankValues) {
      if (Object.keys(blankKeys).length > 0) {
        this.missingKeyColumns[this.gridName] = blankKeys;
      }
      if (Object.keys(blankValues).length > 0) {
        this.missingValueColumns[this.gridName] = blankValues;
      }
    },
    getSelection() {
      let current = this.gv.getCurrent();
      let itemIndex = current.itemIndex;
      if (itemIndex === -1) {
        this.$emit('notSelectiionNotice');
      } else {
        this.$emit('selectionRow', itemIndex, this.gv.getValues(itemIndex));
      }
    },
    // 특별한 컬럼 값만 변경하기위한 코드. - 기본배송지 설정
    setSelectionValue(itemIndex, row) {
      if (this.gridName == 'partnerAddressList') {
        const jsonRows = this.dp.getJsonRows(0, -1);
        // const defaultItems = _.filter(jsonRows, item => item.defaultYn == 1);
        // 필터링된 각 항목의 원래 데이터 배열에서의 인덱스를 찾음
        const defaultItemsWithIndex = _.filter(jsonRows, item => item.defaultYn == 1).map(item => {
          const index = jsonRows.indexOf(item);
          return { ...item, index: index };
        });
        if (defaultItemsWithIndex && defaultItemsWithIndex[0]) {
          this.gv.setValue(defaultItemsWithIndex[0].index, 'defaultYn', 0);
        }
        this.gv.setValue(itemIndex, 'defaultYn', row.defaultYn);
      }
    },
    // 빈 값의 다른 콧을 포커싱하기 위함.(border color 잘 보이게 하기 위함.)
    // setColumnFocused(rowIndex, fieldName) {
    //   this.gv.setCurrent({
    //     itemIndex: rowIndex,
    //     column: fieldName,
    //   });
    // },
    setFilterPanel(visible) {
      this.gv.filterPanel.visible = visible;
      // this.gv.onFilteringChanged = () => {
      //   const filteredData = this.getJsonRows();
      //   this.$emit('filterChanged', filteredData);
      // };
    },
    removeRow(rowIndex) {
      this.dp.removeRow(rowIndex);
    },
    updateAddress(rowIndex, newAddress1, newAddress2) {
      const updatedFullAddress = `${newAddress1 || ''} ${newAddress2 || ''}`.trim();

      this.dp.setValue(rowIndex, 'address1', newAddress1);
      this.dp.setValue(rowIndex, 'address2', newAddress2);
      this.dp.setValue(rowIndex, 'fullAddress', updatedFullAddress);

      // 변경된 행 새로고침
      this.gv.refreshRow(rowIndex);
    },
    // 배송지 목록 그리드에서 행 클릭 시 우측에 버튼 표시.
    // updateButtonPosition(rowIndex) {
    //   this.$nextTick(() => {
    //     const gridContainer = this.$refs.realgrid;
    //     const rows = gridContainer.querySelectorAll('.rg-data-row');
    //     if (rows.length > rowIndex) {
    //       const rowElement = rows[rowIndex];
    //       const rect = rowElement.getBoundingClientRect();
    //       const addTop = 0;
    //       const top = rect.height * (rowIndex + 2) + addTop;
    //       // this.buttonContainerStyle.top = `${top + window.scrollY}px`;
    //       this.buttonContainerStyle.top = `${top + 20}px`;

    //       const gridRect = gridContainer.getBoundingClientRect();
    //       this.buttonContainerStyle.left = `${gridRect.width + window.scrollX + 21}px`; // 그리드 오른쪽에 위치

    //     }
    //   });
    // },
    // onAddressEditClick(event) {
    //   const rowIndex = event.detail;

    //   if (rowIndex !== null) {
    //     const selectedRow = { ...this.dp.getJsonRow(this.selectedRow.rowIndex), rowIndex: this.selectedRow.rowIndex };
    //     EventBus.$emit('openAddressModal', { type: 'edit', data: selectedRow });
    //   }
    // },
    // onAddressCopyClick(event) {
    //   const rowIndex = event.detail;

    //   if (rowIndex !== null) {
    //     const selectedRow = { ...this.dp.getJsonRow(this.selectedRow.rowIndex), rowIndex: this.selectedRow.rowIndex, defaultYn: '0' };
    //     const newRow = { ...selectedRow, idx: '', defaultYn: '0' };
    //     EventBus.$emit('openAddressModal', { type: 'copy', data: newRow });
    //   }
    // },
    // onAddressEditClick() {
    //   // this.postEditType = 'edit';
    //   // this.$emit('onAddressCopyEdit', this.postEditType, this.selectedRow);

    //   if (this.selectedRow) {
    //     this.selectedRow = { ...this.dp.getJsonRow(this.selectedRow.rowIndex), rowIndex: this.selectedRow.rowIndex };
    //     EventBus.$emit('openAddressModal', { type: 'edit', data: this.selectedRow });
    //   }
    // },
    // onAddressCopyClick() {
    //   // this.postEditType = 'copy';
    //   // this.$emit('onAddressCopyEdit', this.postEditType, this.selectedRow);
    //   if (this.selectedRow) {
    //     this.selectedRow = { ...this.dp.getJsonRow(this.selectedRow.rowIndex), rowIndex: this.selectedRow.rowIndex, defaultYn: '0' };
    //     const newRow = { ...this.selectedRow, idx: '', defaultYn: 0 }; // 복사 시 idx 초기화
    //     EventBus.$emit('openAddressModal', { type: 'copy', data: newRow });
    //   }
    // },
    initializedGrid() {
      console.log("initializedGrid");
      this.dp = new LocalDataProvider(true);
      this.gv = new GridView(this.gridName);
      this.gv.displayOptions.fitStyle = this.settings?.fitStyle || 'even';
      this.gv.displayOptions.syncGridHeight = 'none';
      this.gv.displayOptions.rowHeight = this.settings?.rowHeight || 36;
      this.gv.setDataSource(this.dp);
      this.dp.setFields(this.settings.fields);
      this.gv.header.height = 36;
      this.gv.setColumns(this.settings.columns);

      // console.log("gv",this.gridName );
      this.gv.setPasteOptions({ // 그리드 엑셀 붙여넣기 옵션
          enabled: true,  
          startEdit: false,
        });

      // if ( this.gridName === 'adminSample' || this.gridName === 'newSample' || this.gridName == 'sampleMasterDetail' || this.gridName == 'delivaryReportDetail' ) {       
      //   // console.log("setPasteOptions", this.gridName)
      //   this.gv.setPasteOptions({ // 그리드 엑셀 붙여넣기 옵션
      //     enabled: true,  
      //     // selectBlockPaste: true,
      //     startEdit: false,
      //     // singleMode: false,
      //     // forceRowValidation: true,
      //   });
      // }
      // else
      // {
      //   this.gv.setPasteOptions({
      //     enabled: false,
      //   });
      // };

      this.gv.filterMode = this.settings?.filterMode || 'auto';

      this.setGridHeight();

      if (this.gridName === 'delivaryReportDetail') {
        // eslint-disable-next-line no-unused-vars
        this.gv.onKeyPress = function (_grid, _key) {
          this.$emit('keypressEvent', true);
        }.bind(this);
      }
      this.gv.setFooter({
        visible: false,
      });
      // checkbox, radio 둘 중 하나로 변경.
      this.gv.setCheckBar({
        exclusive: this.settings.radio,
      });
      this.gv.onCellDblClicked = (_grid, clickData) => {      
        console.log(clickData.cellType ,"cellType");
        if (clickData.cellType !== "header") {
          const idx = clickData.dataRow;
          const dbClickRow = this.dp.getJsonRow(idx);
          this.$emit('dbClick', dbClickRow);
        }
      };
            // syncHeadCheck: true 리스트 개별 선택 시 전체 선택연동
      this.gv.setCheckBar({
        visible: !this.settings.hideCheckBar,
        showAll: !this.settings.hideCheckBar, // true,
        syncHeadCheck: !this.settings.hideCheckBar, //true,
      });
      if (!this.settings.hideCheckBar) {
        this.gv.onCellClicked = (grid, clickData) => {
          this.$emit('click', { grid, clickData });
        };
      }
      this.gv.onCurrentChanging = grid => {
        grid.commit();
      };
      this.gv.onEditRowChanged = (grid, itemIndex, dataRow, field, oldValue, newValue) => {
        // 그리드의 붉은색 빈값 셀의 값을 체크해서 원복 하는 코드.
        // 그리드가 한 페이지에 여러개일 경우를 대비해서 그리드명을 가지고 가는 구조로 변경을 한다.
        // this.missingValueColumns[그리드명][v1];
        // this.missingKeyColumns[그리드명][v1];
        if (this.gridName == 'delivaryReportDetail' || this.gridName == 'settings_sample_detail' || this.gridName == 'settings_real_detail' || this.gridName == 'settings_make_detail') {
          if (this.missingValueColumns[this.gridName] != undefined && Object.keys(this.missingValueColumns[this.gridName]).length > 0) {
            for (let v1 in this.missingValueColumns[this.gridName]) {
              let p1 = grid.getValue(this.missingValueColumns[this.gridName][v1].rowIndex, v1);
              if (p1 != undefined && p1 != '') {
                this.missingValueColumns[this.gridName][v1].target.classList.remove('rg-validation-focus');
                delete this.missingValueColumns[this.gridName][v1];
                if (Object.keys(this.missingValueColumns[this.gridName]).length === 0) {
                  delete this.missingValueColumns[this.gridName];
                }
              }
            }
          }
          if (this.missingKeyColumns[this.gridName] != undefined && Object.keys(this.missingKeyColumns[this.gridName]).length > 0) {
            for (let k1 in this.missingKeyColumns[this.gridName]) {
              let p2 = grid.getValue(this.missingKeyColumns[this.gridName][k1].rowIndex, k1);
              if (p2 != undefined && p2 != '') {
                this.missingKeyColumns[this.gridName][k1].target.classList.remove('rg-validation-focus');
                delete this.missingKeyColumns[this.gridName][k1];
                if (Object.keys(this.missingKeyColumns[this.gridName]).length === 0) {
                  delete this.missingKeyColumns[this.gridName];
                }
              }
            }
          }
        }
        grid.commit();
        this.$emit('changeData', {
          grid,
          itemIndex,
          dataRow,
          field,
          oldValue,
          newValue,
        });
      };
      if (this.settings.grouping) {
        this.setGroup(this.settings.grouping);
      }
      if (this.settings.fixRow) {
        this.gv.setFixedOptions({ rowCount: this.settings.fixRow });
      }
      if (this.settings.fixCol) {
        this.gv.setFixedOptions({ colCount: this.settings.fixCol });
      }
      this.gv.setStateBar({ visible: false });
      this.gv.setRowIndicator({
        headText: '번호',
      });
      if (this.settings.noneNo) {
        this.gv.setRowIndicator({
          headText: '번호',
          visible: false,
        });
      }
      // 라인 포커스 칼라 주는 내용.
      // if (this.oneLineGrid) {
      //   this.gv.displayOptions.useFocusClass = false;
      // } else {
         this.gv.displayOptions.useFocusClass = true;
      // }
      //this.gv.displayOptions.useFocusClass = this.oneLineGrid ? true : false;

      if (this.settings.noneHeader) {
        this.gv.header.visible = false;
      }
      this.gv.onValidateColumn = function (grid, column, inserting, value) {
        //, itemIndex, dataRow) {
        if (value == undefined) return;
        const error = {};
        if (column.fieldName.includes('Vol')) {
          const valid = validSet.numberDot(value);
          if (valid !== true) {
            error.level = 'error';
            error.message = valid;
          }
        }
        if (error.message) {
          alert(error.message);
        }
        return error;
      };

      if (this.gridName === 'settings_sample_detail') {
        this.gv.columnByField('ww').visible = false;
      }
      this.gv.onCellItemClicked = (_grid, index) => {
        if (this.gridName == 'partnerAddressList') {
          return false;
        }
        let row = this.dp.getJsonRow(index.dataRow);
        this.$emit('btnClick', row);
        return true;
      };
      // 버튼 클릭 이벤트 핸들러 설정
      this.gv.onCellClicked = (grid, cell) => {
        if (this.gridName == 'newPartner' || this.gridName == 'partnerMemberList' || this.gridName == 'partnerAddressList' || this.gridName == 'partnerSearchGrid' || this.gridName == 'addressPTSearchList') {
          this.gv.displayOptions.useFocusClass = true;
        }
        
        if(this.gridName == 'cda')
        {
          console.log(cell,"cell");
          this.$emit('click', cell);
        }

        if (this.gridName == 'partnerAddressList') {
          if (cell.cellType == 'gridEmpty' || cell.dataRow == undefined) {
            return false;
          }
          const rowIndex = cell.dataRow;
          const rData = this.dp.getJsonRow(rowIndex);

          if (cell.column === 'remove' && cell.itemIndex != undefined) {
            if (rData && rData.defaultYn == '1') {
              return false;
            }
            this.$emit('removeData', rData);
            this.removeRow(cell.dataRow);
          } else if (cell.column === 'edit') {
            const row = { ...rData, rowIndex: rowIndex, editabled: cell.column === 'edit' ? true : false };
            this.$emit('btnClick', row);
          } else if (cell.column === 'copy') {
            const row = { ...rData, editabled: cell.column === 'edit' ? true : false };
            this.$emit('btnClick', row);
          }

          // if (cell.dataRow >= 0) {
          //   this.selectedRow = { ...this.dp.getJsonRow(cell.dataRow), rowIndex: cell.dataRow };
          //   // this.updateButtonPosition(cell.dataRow);
          // } else {
          //   this.selectedRow = null;
          // }
        } else if (this.gridName == 'partnerSearchGrid' || this.gridName == 'addressPTSearchList') {
          const rData = this.dp.getJsonRow(cell.dataRow);
          this.$emit('onClickedRow', rData);
        }
      };

      // this.gv.onCurrentRowChanged = (grid, oldRow, newRow) => {
      // if (this.gridName != 'partnerAddressList') {
      //   return false;
      // }
      // if (newRow >= 0) {
      //   this.selectedRow = { ...this.dp.getJsonRow(newRow), rowIndex: newRow };
      //   this.updateButtonPosition(newRow);
      // } else {
      //   this.selectedRow = null;
      // }
      // };
      // 버튼 클릭 이벤트 핸들러 설정
      // this.gv.onCellButtonClicked = (grid, cell) => {
      //   if (cell.column === 'remove') {
      //     this.removeRow(cell.dataRow);
      //   }
      // };
      // if (
      //   (this.gridName === "adminSample" && this.dp.getRowCount() <= 18) ||
      //   this.gridName === "delivaryReportDetail" ||
      //   this.gridName === "settings_sample_detail" ||
      //   this.gridName === "settings_real_detail" ||
      //   this.gridName === "settings_make_detail"
      // ) {
      //   this.gv.displayOptions.syncGridHeight = "always";
      // } else if (this.gridName === "adminSample" && this.dp.getRowCount() > 18) {
      //   this.gv.displayOptions.syncGridHeight = "none";
      // } else {
      //   this.gv.displayOptions.syncGridHeight = "none";
      // }

      this.gv.editOptions.commitByCell = true; // 셀 편집이 완료되면 바로 commit
      this.gv.editOptions.commitWhenLeave = true; // 편집중에 그리드 외부 영역을 클릭하였을때 commit
      //***셀 버튼 클릭시 onCellButtonClicked()이나 onCellItemClicked()이 기본적으로 발생함.
      //***이외에 추가로 onCellClicked()이 같이 발생하는데 이것을 방지하고 싶을때..
      // this.gv.editOptions.exceptDataClickWhenButton = true;
    },
    setGridHeight() {
      const rowCount = this.dp.getRowCount();
      if ((this.gridName === 'sampleRequestDeatil' && rowCount <= 12) || (this.gridName === 'reportDetail' && rowCount <= 17) || (this.gridName === 'coaDetail' && rowCount <= 15) || (this.gridName === 'settings_spec_detail' && rowCount <= 14) || (this.gridName === 'settings_real_spec_detail' && rowCount <= 14) || this.gridName === 'delivaryReportDetail' || this.gridName === 'settings_sample_detail' || this.gridName === 'settings_real_detail' || this.gridName === 'settings_make_detail' || this.gridName === 'menstrummDetail' || this.gridName === 'actDetailGrid' || this.gridName === 'materialIndexDetail' || this.gridName === 'gridDetail') {
        this.gv.displayOptions.syncGridHeight = 'always';
      } else if (this.gridName === 'newSample' || this.gridName === 'adminSample') {
        const targetGrid = document.querySelector(`div#${this.gridName}`);
        if (rowCount >= 12 || this.gv.displayOptions.syncGridHeight === 'none') {
          targetGrid.style.height = this.settings.height + 'px';
        }
        this.gv.displayOptions.syncGridHeight = rowCount >= 12 ? 'none' : 'always';
        // } else if (this.gridName === 'partnerAddressList') {
        //   // this.gv.displayOptions.syncGridHeight = 'over';
        //   const targetGrid = document.querySelector(`div#${this.gridName}`);
        //   if (rowCount >= 3 || this.gv.displayOptions.syncGridHeight === 'none') {
        //     targetGrid.style.height = this.settings.height + 'px';
        //   }
        //   this.gv.displayOptions.syncGridHeight = rowCount >= 3 ? 'none' : 'always';
      } else {
        this.gv.displayOptions.syncGridHeight = 'none';
      }
    },
  },
  mounted() {
    // 샘플요청화면에서 초기화를 다시 하기 위해서 별도로 분리함.
    this.initializedGrid();
    if (this.settings.blankGrid) {
      this.isSpecialGrid = true;
    }
    this.setGridHeight();
    if (this.$refs.gridName == 'newPartner') {
      this.$refs.grid.loadData([]); // 빈 데이터로 초기화
    }

    // if (this.gridName == 'partnerAddressList') {
    //   window.addEventListener('edit-click', this.onAddressEditClick);
    //   window.addEventListener('copy-click', this.onAddressCopyClick);
    // }
  },
  updated() {
    // if ((this.gridName === 'sampleRequestDeatil' && this.dp.getRowCount() <= 12)
    //  || this.gridName === 'delivaryReportDetail'
    //  || this.gridName === 'settings_sample_detail'
    //  || this.gridName === 'settings_real_detail'
    //  || this.gridName === 'settings_make_detail'
    //  || (this.gridName === 'reportDetail' && this.dp.getRowCount() <= 17)
    //  || (this.gridName === 'coaDetail' && this.dp.getRowCount() <= 15)
    //  || this.gridName === 'menstrummDetail'
    //  || this.gridName === 'actDetailGrid'
    //  || this.gridName === 'materialIndexDetail'
    //  || this.gridName === 'gridDetail'
    //  || (this.gridName === 'settings_spec_detail' && this.dp.getRowCount() <= 14) || (this.gridName === 'settings_real_spec_detail' && this.dp.getRowCount() <= 14))
    // {
    //   this.gv.displayOptions.syncGridHeight = 'always';
    // } else if (this.gridName === 'newSample' || this.gridName === 'adminSample') {
    //   if (this.dp.getRowCount() >= 12) {
    //     this.gv.displayOptions.syncGridHeight = 'none';
    //   } else {
    //     this.gv.displayOptions.syncGridHeight = 'always';
    //   }
    //   // } else if (this.gridName === 'adminSample' && this.dp.getRowCount() > 17) {
    //   //   this.gv.displayOptions.syncGridHeight = 'none';
    // } else {
    //   this.gv.displayOptions.syncGridHeight = 'none';
    // }
    this.setGridHeight();
    this.commonSetFitLayout();
  },
  beforeDestroy() {
    if (this.gv) {
      this.gv.destroy();
      this.gv = null;
    }

    // if (this.gridName == 'partnerAddressList') {
    //   // 이벤트 리스너 제거
    //   window.removeEventListener('edit-click', this.onAddressEditClick);
    //   window.removeEventListener('copy-click', this.onAddressCopyClick);
    // }
  },
};
</script>
<style>
.v-pagination__item {
  box-shadow: none !important;
}
.rg-footer,
.rg-foot {
  visibility: hidden;
}
.disabled-color {
  background: #111111;
}
.normal-color {
  background-color: rgb(130, 6, 6); /* 일반 색상으로 변경 */
}
/* .rg-data-row {
  background-color: #ededed;
} */
.rg-focused-cell {
  background: rgb(255, 223, 197) !important;
  /* background: #54d083 !important; */
}
.rg-focused-row {
  background: rgb(249, 239, 229) !important;
  /* background: #bae3bf !important; */
}
.rg-validation-focus {
  /* 포커스 셀의 색깔 변경 */
  border: 2px solid red !important;
}

/* ... */

.rg-selection-cell {
  /* 선택 영역의 색깔 변경 */
  background: #0000ff40 !important;
  color: inherit;
}

.rg-selection {
  background: rgba(229, 102, 21, 0.2) !important;
  border: 1px solid rgba(229, 102, 21, 0.67);
}
.specialGrid {
  width: 100%;
}
.rg-fixed-column-bar {
  background: #00592d !important;
  border-right: 1px solid #d2d5da !important;
}
.rg-filter-panel-renderer .rg-filter-panel-operator {
  display: none !important;
}
.button-container {
  display: flex;
}
.button-container .btn-row-address {
  border-radius: 12px;
  padding: 0 14px;
}
.button-container .btn-address-edit {
  background: #5a9549;
  color: #fff;
}
.button-container .btn-address-copy {
  background: #fff;
  border: 1px solid #5a9549;
  color: #5a9549;
  margin-left: 2px;
}
</style>
